<template>
    <div
        class="min-h-screen flex flex-col justify-center items-center space-y-8 md:space-y-12 lg:space-y-14 bg-cosmicDark text-white text-center lowercase"
    >
        <img
            src="./assets/logo.png"
            alt="Cosmic Fox"
            class="w-32 md:w-48 lg:w-64"
        />

        <div>
            <h1 class="text-4xl md:text-5xl ">Cosmic Fox</h1>
            <h3 class="font-light text-lg md:text-xl opacity-50">
                Front-end development
            </h3>
        </div>
        <div>
            <a
                href="mailto:arya@cosmicfox.io"
                class="transition-colors duration-300 text-cosmicLight hover:text-white text-md lg:text-xl"
            >
                Contact me
            </a>
        </div>
    </div>
</template>

<script>
export default {
    name: "App",
    components: {}
};
</script>
